import styled, { css } from "styled-components"
import { font } from "../../../atoms/typography"
import { color } from "../../../atoms/colors"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (min-width: 770px) {
    max-width: 770px;
    margin: 0px auto;
  }
  @media (min-width: 992px) {
    display: none;
  }
`

export const Heading = styled.p`
  ${font("text", "lg", "700")}
  color: ${color.common.black};
`

export const Group = styled.div``

export const BorderGroup = styled.div`
  overflow: hidden;
  border: 1px solid ${color.grey[200]};
  border-radius: 8px;
`
export const ItemHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${font("text", "reg", "700")}
  color: ${color.grey[600]};
  padding: 8px 24px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 16px;
`

export const Item = styled.div`
  &.item-0 {
    ${ItemHeading} {
      padding: 0px 0px 16px 0px;
    }
    ${Content} {
      padding: 0px 0px 16px 0px;
    }
  }
  &.item-1 {
    border: 1px solid ${color.grey[200]};
    color: ${color.common.white};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom: ${props =>
      props.groupSize === 3 ? "none" : `1px solid ${color.grey[200]}`};
    ${props =>
      props.groupSize === 2 &&
      css`
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      `}
    .heading {
      background-color: ${color.common.cosmicGrid};
      color: ${color.common.white};
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
  &.item-2 {
    border: 1px solid ${color.grey[200]};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top: none;
    background-color: #f9fafb;
    .heading {
      color: ${color.common.white};
      background-color: ${color.restfulGrey[500]};
    }
  }
`

export const IconWrapper = styled.div`
  height: 24px;
  width: 24px;
`

export const Subheading = styled.div`
  ${font("text", "reg", "400")}
  color: ${color.grey[600]};
`

export const Tooltip = styled.div`
  display: flex;
  position: relative;
  text-align: left;
  &:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
  }
  .tooltip-content {
    min-width: 250px;
    top: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 16px;
    ${font("text", "sm", "400")}
    color: #475467;
    background-color: #f2f4f7;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.8s;
    i {
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -12px;
      width: 24px;
      height: 12px;
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        left: 50%;
        transform: translate(-50%, 50%) rotate(45deg);
        background-color: #f2f4f7;
      }
    }
  }
`
