import styled, { css } from "styled-components"
import { font } from "../../../atoms/typography"
import { color } from "../../../atoms/colors"
import { buttonThemes } from "../../../atoms/colors"

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 48px 0px;
  @media (min-width: 992px) {
    padding: 64px 0px;
  }
  @media (min-width: 1170px) {
    padding: 96px 0px;
  }
  ${({ noTopPadding, noBottomPadding }) => css`
    padding-top: ${noTopPadding === true && 0};
    padding-bottom: ${noBottomPadding === true && 0};
    @media (min-width: 992px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
    @media (min-width: 1170px) {
      padding-top: ${noTopPadding === true && 0};
      padding-bottom: ${noBottomPadding === true && 0};
    }
  `}
`
export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 802px;
  margin: 0px auto;
  padding: 0px 16px;
  text-align: center;
`

export const HeadingKicker = styled.p`
  ${font("kicker", "reg", "900")}
  color: ${color.common.workgridBlue};
  margin-bottom: 24px;
`

export const Heading = styled.h2`
  ${font("display", "sm", "900")}
  color: ${color.common.black};
  margin-bottom: 16px;
  @media (min-width: 992px) {
    ${font("display", "md", "900")}
  }
  @media (min-width: 1170px) {
    ${font("display", "lg", "900")}
  }
`

export const Subheading = styled.div`
  ${font("text", "reg", "400")}
  color: ${color.grey[600]};
  @media (min-width: 1170px) {
    ${font("text", "lg", "400")}
  }
`

export const Content = styled.div`
  width: 100%;
  margin: 0px auto;
  padding: 0px 16px;
  @media (max-width: 992px) {
    max-width: 802px;
  }
  @media (max-width: 1170px) {
    max-width: 1202px;
  }
`

export const CTABorder = styled.div`
  padding: 16px 24px 24px;
  border-left: 2px solid ${color.common.cosmicGrid};
  border-right: 2px solid ${color.common.cosmicGrid};
  border-bottom: 2px solid ${color.common.cosmicGrid};
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`

export const CTA = styled.a`
  display: flex;
  justify-content: center;
  width: 100%;
  ${buttonThemes.primary}
  ${font("text", "reg", "700")}
`
