export const sortMobileData = data => {
  const fullMobileData = []

  data?.columns.map(column => {
    column.items.map((item, itemIdx) => {
      if (fullMobileData[itemIdx] === undefined) {
        fullMobileData.push([item])
      } else {
        fullMobileData[itemIdx].push(item)
      }
    })
  })
  return fullMobileData
}
