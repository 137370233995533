import styled from "styled-components"
import { font } from "../../../atoms/typography"
import { color } from "../../../atoms/colors"

export const Wrapper = styled.div`
  display: none;
  @media (min-width: 992px) {
    display: block;
    width: 100%;
    max-width: 770px;
    margin: 0 auto;
  }
  @media (min-width: 1170px) {
    max-width: ${props => (props.groupSize === 3 ? "1170px" : "770px")};
  }
`

export const HeadingRow = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.groupSize === 3 ? "repeat(3, 1fr)" : "repeat(2, 1fr)"};
`

export const Heading = styled.div`
  ${font("text", "lg", "700")}
  color: ${color.common.black};
  padding: 24px;
  &.heading-1 {
    background: ${color.common.cosmicGrid};
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    color: ${color.common.white};
  }
`

export const Table = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.groupSize === 3 ? "repeat(3, 1fr)" : "repeat(2, 1fr)"};
  grid-auto-rows: 1fr;
`
export const Group = styled.div`
  display: grid;
  grid-auto-rows: 1fr;
  &.group-0 {
    border: none;
    border-top: 1px solid transparent;
    background-image: linear-gradient(to right, transparent, #e4e7ec),
      linear-gradient(to right, transparent, #e4e7ec);
    background-repeat: no-repeat;
    background-size: 100% 1px;
    background-position: top, bottom;
    .item {
      border: none;
      border-top: 1px solid transparent;
      background-image: linear-gradient(to right, transparent, #e4e7ec);
      background-repeat: no-repeat;
      background-size: 100% 1px;
      background-position: bottom;
    }
  }
  &.group-1 {
    border-left: 2px solid ${color.common.cosmicGrid};
    border-right: 2px solid ${color.common.cosmicGrid};
  }
  &.group-2 {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    border-top: 1px solid ${color.grey[200]};
    border-right: 1px solid ${color.grey[200]};
    border-bottom: 1px solid ${color.grey[200]};
    background-color: #f9fafb;
  }
`

export const BorderGroup = styled.div`
  overflow: hidden;
  border: 1px solid ${color.grey[200]};
  border-radius: 8px;
`

export const Item = styled.div`
  padding: 16px 24px;
  border-bottom: 1px solid ${color.grey[200]};
  &:last-child {
    border-bottom: none;
  }
`
export const ItemHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  ${font("text", "reg", "700")}
  color: ${color.grey[600]};
  margin-bottom: 8px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (min-width: 1170px) {
    flex-direction: row;
  }
`

export const IconWrapper = styled.div`
  height: 24px;
  width: 24px;
`

export const Subheading = styled.div`
  ${font("text", "reg", "400")}
  color: ${color.grey[600]};
`

export const CtaRow = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.groupSize === 3 ? "repeat(3, 1fr)" : "repeat(2, 1fr)"};
`

export const Tooltip = styled.div`
  display: flex;
  position: relative;
  text-align: left;
  &:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
  }
  .tooltip-content {
    min-width: 250px;
    top: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 16px;
    ${font("text", "sm", "400")}
    color: #475467;
    background-color: #f2f4f7;
    border-radius: 8px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.8s;
    i {
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -12px;
      width: 24px;
      height: 12px;
      overflow: hidden;
      &:after {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        left: 50%;
        transform: translate(-50%, 50%) rotate(45deg);
        background-color: #f2f4f7;
      }
    }
  }
`
