import React from "react"
import DesktopTable from "./components/DesktopTable"
import MobileTable from "./components/MobileTable"
import {
  Section,
  HeadingWrapper,
  HeadingKicker,
  Heading,
  Subheading,
  Content,
} from "./styles/UseCaseComponent.styled"

const UseCase = ({ component }) => (
  <Section noTopPadding={component?.noTopPadding} noBottomPadding={component?.noBottomPadding}>
    <HeadingWrapper>
      {component?.headingKicker && (
        <HeadingKicker>{component?.headingKicker}</HeadingKicker>
      )}
      {component?.heading && <Heading>{component?.heading}</Heading>}
      {component?.subheading?.subheading && (
        <Subheading>{component?.subheading?.subheading}</Subheading>
      )}
    </HeadingWrapper>
    <Content>
      <DesktopTable component={component} />
      <MobileTable component={component} />
    </Content>
  </Section>
)

export default UseCase
