import React from "react"
import { CTA } from "../styles/UseCaseComponent.styled"
import {
  Wrapper,
  Group,
  Heading,
  Item,
  Content,
  ItemHeading,
  Subheading,
  IconWrapper,
  Tooltip,
} from "../styles/MobileTable.styled"
import { sortMobileData } from "../utils/sortMobileData"
import tooltip from "../assets/Tooltip.svg"
import { defaultResolveRichText } from "../../../utils/resolve-rich-text/defaultResolveRichText"

const MobileTable = ({ component }) => {
  const sortedMobileData = sortMobileData(component)
  const groupSize = sortedMobileData[0].length
  const heading = component?.columns[0]?.heading

  return (
    <Wrapper>
      <Heading>{heading}</Heading>
      {sortedMobileData.map(group => (
        <Group>
          {group.map((item, itemIdx) => (
            <Item className={`item-${itemIdx}`} groupSize={groupSize}>
              {item?.heading && (
                <ItemHeading className="heading">
                  {item?.heading}
                  {item?.tooltip && (
                    <Tooltip>
                      <img src={tooltip} alt="icon" />
                      <div class="tooltip-content">
                        <div>{item?.tooltip?.tooltip}</div>
                        <i></i>
                      </div>
                    </Tooltip>
                  )}
                </ItemHeading>
              )}
              <Content>
                {item?.icon && (
                  <IconWrapper>
                    <img src={item?.icon?.url} alt="icon" />
                  </IconWrapper>
                )}
                <Subheading className="subheading">
                  {defaultResolveRichText(item?.subheading)}
                </Subheading>
              </Content>
            </Item>
          ))}
        </Group>
      ))}
      {component?.callToAction?.link && (
        <CTA href={component?.callToAction?.link}>
          {component?.callToAction?.label}
        </CTA>
      )}
    </Wrapper>
  )
}

export default MobileTable
